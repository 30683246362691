import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Preview from './Preview';

const ClimateRecipe = (props) => {
    const DEFAULT_START_HOUR = 23;
    const SLOT_DURATION = 1800;
    const [baseRecipeFile, setBaseRecipeFile] = useState('')
    const [priceFile, setPriceFile] = useState('')
    const [response, setResponse] = useState({})
    const [showDownloads, setShowDownloads] = useState(false)
    const [showPreview, setShowPreview] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShowDownloads(false)
        setShowPreview(false)
        setResponse({})
        if (!baseRecipeFile || baseRecipeFile.length === 0) {
            alert('Please choose a base recipe file (json)')
            return
        }
        if (!priceFile || priceFile.length === 0) {
            alert('Please choose an EPEX price file (json)')
            return
        }
        const fData = new FormData()
        fData.append('base_recipe', baseRecipeFile)
        fData.append('prices', priceFile)
        try {
            const response = await fetch('https://climateapi.vimgrep.in/modify_climate_recipe', {
                method: 'POST',
                body: fData,
                credentials:'include'
            })
            const code = response.status
            const body = await response.json()
            if(code !== 200) {
                setShowDownloads(false)
                alert(body.message || 'Something went wrong!!')
                if(code === 401) {
                    props.onLogout()
                }
            } else {
                if(body.code < 0) {
                    alert(`ERROR| ${body.error || 'Something went wrong!!'}`);
                    return;    
                }
                alert('Optimiser ran Successfully. Click PREVIEW to see the results.')
                setShowDownloads(true)
                const {ms = -2, mc = -2} = body
                console.log(`ms : ${ms}, mc: ${mc}`)
                setResponse(body.response || {})
            }
        } catch(e) {
            alert(`Something went wrong [${e}]!!`)
        }
    }

    const handleLogout = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch('https://climateapi.vimgrep.in/logout', {
                method: 'POST',
                credentials:'include'
            })
            const code = response.status
            const body = await response.json()
            if(code !== 200) {
                alert(body.message || 'Something went wrong!!')
                if(code === 401) {
                    props.onLogout()
                }
            } else {
                // Show login page
                props.onLogout()
            }
        } catch(e) {
            alert(`Something went wrong [${e}]!!`)
        }
    }

    const getRecipeData = () => {
        const {recipe_data : {modified_recipe = {}} = {}} = response
        const enc = btoa(JSON.stringify(modified_recipe))
        return `data:application/json;base64, ${enc}`
    }

    const getResultsData = () => {
        const {recipe_data : {comparison : {data = []} = {}} = {}} = response
        const results_data = data.map(item => `${item.slot},${item.fanSpeed},${item.heater},${item.modified_fanSpeed},${item.modified_heater}`)
        const header = `Algo Slot, Base recipe fan speed, Base recipe heater, Modified recipe fan speed, Modified recipe heater`
        const enc = btoa([header, ...results_data].join('\n'))
        return `data:text/csv;base64, ${enc}`
    }

    const getDateTimeString = (d) => {
        const dString = d.toISOString().split('.')[0]
        console.log(`[${d.toISOString()}] dString : ${dString}`)
        return `${dString}+00.00`
    }

    const getNiagaraFile = () => {
        const {recipe_data : {modified_recipe = {}} = {}} = response
        let text;
        let dateObj = new Date()
        if (dateObj.getHours() >= DEFAULT_START_HOUR) {
  	        dateObj.setDate(dateObj.getDate() + 1)
        }
        dateObj.setHours(DEFAULT_START_HOUR)
        dateObj.setMinutes(0)
        dateObj.setSeconds(0)
        const rec = modified_recipe.map(m => {
            const {values: {fanSpeed, heater} = {}} = m;
            const start = new Date(dateObj)
            start.setSeconds(start.getSeconds() + (m.slot - 1) * SLOT_DURATION);
            const end = new Date(start)
            end.setSeconds(end.getSeconds() + SLOT_DURATION)
            return {node : {fanSpeedValue : fanSpeed, heaterValue: heater, startAt: getDateTimeString(start), endAt: getDateTimeString(end)}}
        })
        const resp = {
            data: {
               property: {
                  electricityMeterPoints: [
                    {
                        "mpan": "2200043360041",
                        "meters": [
                            {
                              "serialNumber": "K21D06557",
                              "consumption": {
                                 "edges": rec
                              }
                            }
                        ]
                    }
                  ]
                }
            }
        }
        const enc = btoa(JSON.stringify(resp))
        return `data:application/json;base64, ${enc}`
    }

    const handleShowPreview = (e) => {
        setShowPreview(true)
    }

    return (
        <>
        <Button variant="primary" className="button-logout mouseover-logout" onClick={handleLogout}>
            Logout
        </Button>
    <div className='recipe-page-heading'><h1>CLIMATE RECIPE OPTIMISER</h1></div>
      <Form onSubmit={handleSubmit} className="main-form main-form-recipe">
        <Form.Group className="mb-3" controlId="base_recipe">
            <Form.Label><strong>1. Base Recipe File (json)</strong></Form.Label><br/><br/>
            <Form.Control className="file-picker-input" type="file" placeholder="Pick a file" onChange={ e => setBaseRecipeFile(e.target.files[0])} accept="application/json"/>
        </Form.Group>
        <br/><br/><br/>
        <Form.Group className="mb-3" controlId="prices">
            <Form.Label><strong>2. EPEX Price File (json)</strong></Form.Label><br/><br/>
            <Form.Control className="file-picker-input" type="file" placeholder="Pick a file" onChange={ e => setPriceFile(e.target.files[0])} accept="application/json"/>
        </Form.Group>
        <div className='recipe-page-main-btn-ctn'>
        <Button variant="primary" type="submit" className="button-general mouseover-primary">
            Run Optimiser
        </Button>
        </div>
      </Form>
      {showDownloads ?
      <>
        <Button variant="secondary" className="button-general mouseover-secondary" onClick={handleShowPreview}>Preview</Button>
        <a href={getRecipeData()} download="modified_recipe.json" target='_blank' rel="noreferrer">
            <Button variant="secondary" className="button-general mouseover-secondary">Download Modified Recipe</Button>
        </a>
        <a href={getResultsData()} download="results.csv" target='_blank' rel="noreferrer">
        <Button variant="secondary" className="button-general mouseover-secondary">Download Results</Button>
        </a>
        <a href={getNiagaraFile()} download="niagara_recipe.json" target='_blank' rel="noreferrer">
        <Button variant="secondary" className="button-general mouseover-secondary">Download File for Niagara</Button>
        </a>
      </> : null
      }
      {
        showPreview ?
        <Preview data={response}/> : null
      }
      </>
    )
}

export default ClimateRecipe