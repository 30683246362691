import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  scales,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const Preview = (props) => {

  const { recipe_data: { base_recipe = {}, comparison: { data = [] } = {}, prices = [] } = {}, reqBody: {config : {topCount = 1} = {}} = {} } = props.data || {}
  const labels = data.map(d => d.slot)
  const fanSpeed_graph_data_base = data.map(d => d.fanSpeed)
  const fanSpeed_graph_data_modified = data.map(d => d.modified_fanSpeed)
  const heater_graph_data_base = data.map(d => d.heater)
  const heater_graph_data_modified = data.map(d => d.modified_heater)
  const epex_graph_data = prices.map(d => d.price)
  const colors = base_recipe.map(d => d.values.consumptionCode)
  const price_opt = prices.sort((item1, item2) => item2.price - item1.price)[topCount - 1].price
  const price_line = base_recipe.map(_ => price_opt)


  const options_fanSpeed = {
    responsive: true,
    scales: {
      y: {
        min:0,
        max: 100
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'FanSpeed Comparison (Base Vs Modified)',
      },
    },
  };

  const options_heater = {
    responsive: true,
    scales: {
      y: {
        min:0,
        max: 120
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Heater Comparison (Base Vs Modified)',
      },
    },
  };

  const options_epex = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'EPEX Price',
      },
    },
  };

  const data_fanSpeed = {
    labels,
    datasets: [
      {
        label: 'Base Recipe',
        data: fanSpeed_graph_data_base,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: colors,
        fill: true
      },
      {
        label: 'Modified Recipe',
        data: fanSpeed_graph_data_modified,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: colors,
        fill: true
      },
    ],
  };

  const data_heater = {
    labels,
    datasets: [
      {
        label: 'Base Recipe',
        data: heater_graph_data_base,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: colors,
        fill: true
      },
      {
        label: 'Modified Recipe',
        data: heater_graph_data_modified,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: colors,
        fill: true
      },
    ],
  };

  const data_epex = {
    labels,
    datasets: [
      {
        label: 'EPEX Prices',
        data: epex_graph_data,
        borderColor: 'rgb(204, 51, 102)',
        backgroundColor: colors,
        fill: true
      },
      {
        label: 'Price cutoff',
        data: price_line,
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: colors,
        fill: true
      }
    ],
  };

  return (
    <>
      {
        fanSpeed_graph_data_base.length > 0 && fanSpeed_graph_data_modified.length > 0 ?
          <>
            <br /><br />
            <div className='chart-line-wrapper'>
              <Line options={options_fanSpeed} data={data_fanSpeed} />
            </div></> : null
      }
      {
        heater_graph_data_base.length > 0 && heater_graph_data_modified.length > 0 ?
          <>
            <br /><br />
            <div className='chart-line-wrapper'>
              <Line options={options_heater} data={data_heater} />
            </div></> : null
      }
      {
        epex_graph_data.length > 0 ?
          <>
            <br /><br />
            <div className='chart-line-wrapper'>
              <Line options={options_epex} data={data_epex} />
            </div></> : null

      }
    </>

  )
}

export default Preview;